import * as React from 'react';
import { useState } from 'react';
import {
  makeStyles,
  Box,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';

import {
  getAuth,
  getSwaps,
  getAmoSwapBalance,
  getKip7SwapBalance,  
} from './api';

interface Swap {
  amoAddress: string;
  kip7Address: string;  
  transitAddress: string;
}

interface SwapsProps {
  auth: string;
}

const useStyles = makeStyles({
  monospace: {
    fontFamily: 'Monospace',
    fontSize: '1.2em',
  },
});

const Row: React.FC<Swap> = (props) => {
  const row = props;
  const [amoSwap, setAmoSwap] = useState<BigInt>(BigInt(0));
  const [kip7Swap, setKip7Swap] = useState<BigInt>(BigInt(0));  
  const classes = useStyles();

  React.useEffect(() => {
    getAmoSwapBalance(props.transitAddress).then((res) => {
      setAmoSwap(res);
    });    

    getKip7SwapBalance(props.kip7Address).then((res) => {
      setKip7Swap(res);
    });
  }, []);

  return (
    <TableRow key={row.transitAddress}>
      <TableCell className={classes.monospace} >
        <div>{row.amoAddress}</div>
      </TableCell>
      <TableCell className={classes.monospace} >
        <div>{row.transitAddress}</div>
        <div>{amoSwap.toString()} mote</div>
      </TableCell>
      <TableCell className={classes.monospace} >
        <div>{row.kip7Address}</div>
        <div>{kip7Swap.toString()} mote</div>
        <div style={{ color: 'blue' }}>
          대기 { (BigInt(amoSwap) - BigInt(kip7Swap)).toString() } mote
        </div>
      </TableCell>
    </TableRow>
  );
};

const Swaps: React.FC<SwapsProps> = ({auth}) => {
  const [swaps, setSwaps] = useState<Swap[]>([]);

  React.useEffect(() => {
    getSwaps(auth)
    .then((res) => {
      if (res.length > 0) {
        setSwaps(res);
      } else {
        setSwaps([]);
      }
    });
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>AMO 원금</TableCell>
            <TableCell>AMO 입금</TableCell>
            <TableCell>AMO Klaytn(전송대기)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {swaps.map((row) => (
            <Row amoAddress={row.amoAddress} kip7Address={row.kip7Address} transitAddress={row.transitAddress} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const Admin: React.FC = () => {
  const [auth, setAuth] = useState<string>();

  const onSubmit = (values: any) => {
    getAuth(values.password).then((res) => {
      setAuth(res);
    })
  };

  const form = (
    <Form
      onSubmit={onSubmit}
      render={({handleSubmit}) => (
        <form onSubmit={handleSubmit}>
          <TextField
            label="password"
            name="password"
            type="password"
            required={true}
          />
          <Button type="submit"
            color="primary"
            variant="contained"
            style={{ marginTop: '30px' }}
          >
            auth
          </Button>
        </form>
      )}
    />
  );

  return auth ? ( <Swaps auth={auth} />) : (
    <div>
      input admin password
      {form}
    </div>
  );
};

export default Admin;
