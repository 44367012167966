import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  makeStyles,
  Divider,
  Card,
  Typography,
} from '@material-ui/core';
import {
  getAmoBalance,
  getAmoSwapBalance,
  getKip7Balance,
  getKip7SwapBalance,
} from './api';

interface Props {
  address?: string;
};

const useStyles = makeStyles({
  wallet: {
    backgroundColor: '#f0f0f0',
    marginTop: '10px',
    marginBottom: '15px',
    padding: '10px',
  },
  field: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  field_label: {
    flex: 2,
    marginRight: '5px',
  },
  field_value: {
    flex: 8,
    overflow: 'auto',
    //textOverflow: 'ellipsis',
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  divider: {
    margin: '10px',
  }
});

const mote2amo = (mote: BigInt) => {
  const n_mote = Number(mote);
  const ONEAMO = 1000000000000000000;
  return n_mote / ONEAMO;
};

const Account: React.FC<Props> = ({address}) => {
  const classes = useStyles();
  const [balance, setBalance] = useState<BigInt>(BigInt(0));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (address) {
      setLoading(true);
      getAmoBalance(address)
      .then((res) => {
        setBalance(res);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
    }
  }, [address]);

  return address ? (
    <Card className={classes.wallet} variant="elevation">
      <div className={classes.field}>
        <Typography className={classes.field_label}>
          원금 AMO
        </Typography>
        <Typography className={classes.field_value}>
          <div>{address}</div>
          <div>현재 보유하고 계신 메인넷 기반의 AMO Coin입니다.</div>
        </Typography>
      </div>
      <Divider className={classes.divider}/>
      <div className={classes.field}>
        <Typography className={classes.field_label}>
          잔고:
        </Typography>
        <div className={classes.field_value}>
          <div style={{ fontSize: '120%' }}>
            {loading ? 'loading...' : `${mote2amo(balance).toLocaleString()} AMO`}
          </div>
          <div style={{ fontSize: '80%' }}>
            {loading ? 'loading...' : `${balance.toString()} mote`}
          </div>
        </div>
      </div>
    </Card>
  ) : null;
};

const SwapInput: React.FC<Props> = ({address}) => {
  const classes = useStyles();
  const [balance, setBalance] = useState<BigInt>(BigInt(0));
  const [swapBalance, setSwapBalance] = useState<BigInt>(BigInt(0));
  const [loadingAmo, setLoadingAmo] = useState(false);
  const [loadingSwap, setLoadingSwap] = useState(false);

  useEffect(() => {
    if (address) {
      setLoadingAmo(true);
      setLoadingSwap(true);
      getAmoBalance(address)
      .then((res) => {
        setBalance(res);
        setLoadingAmo(false);
      })
      .catch((error) => {
        setLoadingAmo(false);
      });
      getAmoSwapBalance(address)
      .then((res) => {
        setSwapBalance(res);
        setLoadingSwap(false);
      })
      .catch((error) => {
        setLoadingSwap(false);
      });
    }
  }, [address]);

  return address ? (
    <Card className={classes.wallet} variant="elevation">
      <div className={classes.field}>
        <Typography className={classes.field_label}>
          스왑 입금 AMO
        </Typography>
        <Typography className={classes.field_value}>
          <div>{address}</div>
          <div style={{ color: '#ff4000' }}>
            토큰 스왑을 위해서 입금하실 주소입니다.<br/>
            지정된 원금 AMO 주소로부터 입금된 금액만 스왑의 대상이 됩니다.
          </div>
        </Typography>
      </div>
      <Divider className={classes.divider}/>
      <div className={classes.field}>
        <Typography className={classes.field_label}>
          잔고:
        </Typography>
        <div className={classes.field_value}>
          <div style={{ fontSize: '120%' }}>
            {loadingAmo ? 'loading...' : `${mote2amo(balance).toLocaleString()} AMO`}
          </div>
          <div style={{ fontSize: '80%' }}>
            {loadingAmo ? 'loading...' : `${balance.toString()} mote`}
          </div>
        </div>
      </div>
      <Divider className={classes.divider}/>
      <div className={classes.field}>
        <Typography className={classes.field_label}>
          스왑 대상 총액:
        </Typography>
        <div className={classes.field_value}>
          <div style={{ fontSize: '120%' }}>
            {loadingSwap ? 'loading...' : `${mote2amo(swapBalance).toLocaleString()} AMO`}
          </div>
          <div style={{ fontSize: '80%' }}>
            {loadingSwap ? 'loading...' : `${swapBalance.toString()} mote`}
          </div>
        </div>
      </div>
    </Card>
  ) : null;
};

const SwapOutput: React.FC<Props> = ({address}) => {
  const classes = useStyles();
  const [balance, setBalance] = useState<BigInt>(BigInt(0));
  const [swapBalance, setSwapBalance] = useState<BigInt>(BigInt(0));
  const [loadingKip7, setLoadingKip7] = useState(false);
  const [loadingSwap, setLoadingSwap] = useState(false);

  useEffect(() => {
    if (address) {
      setLoadingKip7(true);
      setLoadingSwap(true);
      getKip7Balance(address)
      .then((res) => {
        setBalance(res);
        setLoadingKip7(false);
      })
      .catch((error) => {
        setLoadingKip7(false);
      });
      getKip7SwapBalance(address)
      .then((res) => {
        setSwapBalance(res);
        setLoadingSwap(false);
      })
      .catch((error) => {
        setLoadingSwap(false);
      });
    }
  }, [address]);

  return address ? (
    <Card className={classes.wallet} variant="elevation">
      <div className={classes.field}>
        <Typography className={classes.field_label}>
          스왑 수령 Klaytn
        </Typography>
        <Typography className={classes.field_value}>
          <div>{address}</div>
        </Typography>
      </div>
      <Divider className={classes.divider}/>
      <div className={classes.field}>
        <Typography className={classes.field_label}>
          스왑 완료:
        </Typography>
        <div className={classes.field_value}>
          <div style={{ fontSize: '120%' }}>
            {loadingSwap ? 'loading...' : `${mote2amo(swapBalance).toLocaleString()} AMO(Klaytn)`}
          </div>
          <div style={{ fontSize: '80%' }}>
            {loadingSwap ? 'loading...' : `${swapBalance.toString()} mote`}
          </div>
        </div>
      </div>
    </Card>
  ) : null;
};

export {
  Account,
  SwapInput,
  SwapOutput,
};
