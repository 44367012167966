import { ethers } from 'ethers';

//////////////////// setup
//const serverApi = 'http://localhost:3030';
//const serverApi = 'http://15.164.247.42:3030';
const serverApi = `${document.location.origin}/api`;
const abi = [
  "function balanceOf(address owner) view returns (uint256)",
  "event Transfer(address indexed from, address indexed to, uint amount)",
];
//const provider = new ethers.providers.JsonRpcProvider(
//  //'http://172.105.242.108:51102',
//  'http://139.162.98.239:51102',
//);
//////////////////// setup end

const apiCall = async (
  url: string,
  method?: string,
  body?: object,
  headers?: object
) => {
  return fetch(url, {
    method: method,
    body: body ? JSON.stringify(body) : null,
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
  }).then(async (res) => {
    if (!res.ok) {
      return Promise.reject(await res.json());
    }
    return res.json();
  });
};

interface Swap {
  amoAddress: string;
  kip7Address: string;  
  transitAddress: string;
}

interface searchRequest {
  amoAddress?: string;
  kip7Address?: string;  
}

const getSwap = async (req: searchRequest): Promise<Swap> => {
  if (!req.amoAddress || !req.kip7Address) {
    return Promise.reject({ message: 'Not enough search parameter' });
  }
  return apiCall(`${serverApi}/swaps/${req.amoAddress}/${req.kip7Address}`)
  .then((res) => {
    return {
      // convert snake to camel
      amoAddress: res.amo_address,
      kip7Address: res.kip7_address,    
      transitAddress: res.transit_address,
  }});
};

const getAmoBalance = async (address: string): Promise<BigInt> => {
  return apiCall(`${serverApi}/amo/balance/${address}`)
  .then((res) => BigInt(res));
};

const getAmoSwapBalance = async (address: string): Promise<BigInt> => {
  return apiCall(`${serverApi}/amo/kip7/swap_balance/${address}`)
  .then((res) => {
    try {
      return BigInt(res);
    } catch(ex) {
      console.log(ex);
      return BigInt(0);
    }
  }, (ex2) => {
    console.log(ex2);
    return BigInt(0);    
  });
};

const getKip7Balance = async (address: string): Promise<BigInt> => {
  return apiCall(`${serverApi}/kip7/balance/${address}`)
  .then((res) => BigInt(res));
};

const getKip7SwapBalance = async (address: string): Promise<BigInt> => {
  return apiCall(`${serverApi}/kip7/swap_balance/${address}`)
  .then((res) => BigInt(res));
};

/////////// admin APIs

const getAuth = async (password: string): Promise<string> => {
  return apiCall(`${serverApi}/auth`, 'POST', {
    password: password,
  })
  .then((res) => res.token);
};

const getSwaps = async (auth: string): Promise<Swap[]> => {
  return apiCall(`${serverApi}/swaps`, 'GET', undefined, {
    'Auth': auth,
  })
  .then((res) => res.map((row: any) => {
    return {
      // convert snake to camel
      amoAddress: row.amo_address,
      kip7Address: row.kip7_address,      
      transitAddress: row.transit_address,
    }
  }));
};

export default apiCall;
export {
  getSwap,
  getAmoBalance,
  getAmoSwapBalance,
  getKip7Balance,
  getKip7SwapBalance,
  getAuth,
  getSwaps,
};
