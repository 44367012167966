import * as React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
} from 'react-router-dom';
import './App.css';
import Home from './Home';
import Admin from './Admin';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home/>
        </Route>
        <Route exact path="/admin">
          <Admin/>
        </Route>
        <Route path="*">
          <p>Not Found</p>
          <Link to="/">Go to main</Link>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
