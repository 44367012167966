import * as React from 'react';
import { Box } from '@material-ui/core';
import logo from './amo_white.png';
import RequestSwap from './RequestSwap';

const Home: React.FC = () => (
  <div className="App">
    <header className="App-header">
      <div className="Site-header">
        <img src={logo} className="App-logo" alt="logo" /> <h2>Klaytn AMO 토큰 Swap 신청</h2>
      </div>

      <p className="Site-helper">
        Klaytn AMO 토큰 스왑 신청 사이트에 오신 것을 환영합니다.<br/>
        보고 계신 페이지의 주소가 https://klaytnswap.amolabs.io 가 맞는지 다시 한번 확인하세요.<br/>
        진행 전에 다음 공지사항을 확인해주세요.<br/>
        <a className="App-link" href="https://medium.com/@amoblockchain/amo-%EC%8A%A4%EC%99%91%EA%B0%80%EC%9D%B4%EB%93%9C-b1d2a21544d8/">[공지보기]</a>
        <br/>
        <br/>
        개인지갑에서 코인원 거래소로 이동하실 경우에만 진행하시고,<br/>
        빗썸 거래소 그대로 이용하실 경우 스왑하실 필요 없습니다.<br/>
        <br/>        
        빗썸 거래소에 보관된 AMO 코인은 향후 지원 결정 시 자동으로 스왑되므로 별도의 스왑 신청이 필요 없습니다.<br/>
        <br/>
        신청 후 30분 후에도 스왑이 진행되지 않을 경우 <a className="App-link" href="https://open.kakao.com/o/so0nAvHc" style={{ color: 'white' }}><u>https://open.kakao.com/o/so0nAvHc</u></a>로 문의부탁드립니다.<br/>
      </p>
    </header>
    <div className="App-body">
      <Box p={3} style={{ display: 'flex', justifyContent: 'center' }}>
        <RequestSwap/>
      </Box>
    </div>
  </div>
);

export default Home;
