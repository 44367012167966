import * as React from 'react';
import { Card, Box, Button, Typography } from '@material-ui/core';
import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';
import SwapIcon from '@material-ui/icons/SwapHoriz';
import DownIcon from '@material-ui/icons/ArrowDownward';

import { getSwap, getAmoSwapBalance, getKip7SwapBalance} from './api';
import { Account, SwapInput, SwapOutput } from './Account';

interface FormValues {
  amoAddress?: string;
  kip7Address?: string;  
  requesterName?:string;
  requesterPhone?:string;
}

const mote2amo = (mote: BigInt) => {
  const n_mote = Number(mote);
  const ONEAMO = 1000000000000000000;
  return n_mote / ONEAMO;
};

const validateAMOAddress = (address: string) => {
  if (!address) return false;
  let re = /^[a-fA-F0-9]{40}$/;
  return re.test(address);
};

const validateKip7Address = (address: string) => {
  if (!address) return false;
  let re = /^0x[a-fA-F0-9]{40}$/;
  return re.test(address);
};

const RequestSwap: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [amo, setAmo] = React.useState<string>();
  const [kip7, setKip7] = React.useState<string>();  
  const [rName, setName] = React.useState<string>();
  const [rPhone, setPhone] = React.useState<string>();
  const [transit, setTransit] = React.useState<string>();
  const [kip7ToTransfer, setKip7ToTransfer] = React.useState<BigInt>(BigInt(0));

  React.useEffect(() => {
    if (amo && kip7 && transit) {
      Promise.all([
        getAmoSwapBalance(transit),
        getKip7SwapBalance(kip7),
      ]).then((res: any) => {
        let diff = BigInt(res[0] - res[1]);
        if (diff > 0) {
          setKip7ToTransfer(diff);
        } else {
          setKip7ToTransfer(BigInt(0));
        }
      })
    } else {
      setKip7ToTransfer(BigInt(0));
    }
  }, [amo, kip7, transit]);

  const onSubmit = (values: FormValues) => {
    setLoading(true);
    setAmo(values.amoAddress);
    setKip7(values.kip7Address);    
    setName(values.requesterName);
    setPhone(values.requesterPhone);
    setTransit(undefined);
    getSwap({
      amoAddress: values.amoAddress || '',
      kip7Address: values.kip7Address || '',      
    }).then((res) => {
      setTransit(res.transitAddress.toUpperCase());
      setLoading(false);
    }).catch((error) => {
      setTransit(undefined);
      setLoading(false);
    });
  };

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.amoAddress) {
      errors.amoAddress = '현재 보유중인 메인넷 AMO Coin 지갑의 주소를 입력하세요.';
    } else if (!validateAMOAddress(values.amoAddress)) {
      errors.amoAddress = '올바른 AMO 메인넷 지갑의 주소를 입력하세요.' +
        '예) 20764F1E0655DF18590844AEC08EAA2CE853085B';
    }

    if (!values.kip7Address) {
      errors.kip7Address = 'Klaytn 토큰을 수령할 Klaytn 지갑의 주소를 입력하세요..';
    } else if (!validateKip7Address(values.kip7Address)) {
      errors.kip7Address = '올바른 Klaytn 주소를 입력하세요.' +
        '예) 0x20764F1E0655DF18590844AEC08EAA2CE853085B';
    }
    return errors;
  }

  const form = (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({handleSubmit, values, valid}) => (
        <form onSubmit={handleSubmit}>
          <p>
            현재 보유중인 AMO Coin 지갑 주소와 스왑된 코인을 수령할 Klaytn 지갑
            주소를 입력하세요.
          </p>
          <p className="App-warning">
            개인 지갑 주소만 사용 가능하며 거래소 지갑 주소 사용 시 스왑이 안될
            수 있습니다.<br/>
            보유하고 계신 잔고와 다를 경우 주소를 다시 한번 확인해주십시오.
          </p>
          <TextField
            label="현재 보유중인 메인넷 AMO 지갑 주소"
            name="amoAddress"
            style={{ marginTop: '20px' }}
            required={true}
          />
          <TextField
            label="스왑 후 토큰을 수령하실 Klaytn 주소"
            name="kip7Address"
            style={{ marginTop: '20px' }}
            required={true}
          />
          <TextField
            label="신청자 성함"
            name="requesterName"
            style={{ marginTop: '20px' }}
            required={true}
          />
          <TextField
            label="신청자 연락처(전화번호)"
            name="requesterPhone"
            style={{ marginTop: '20px' }}
            required={true}
          />
          <Button type="submit" disabled={!valid || loading}
            color="primary"
            variant="contained"
            startIcon={<SwapIcon/>}
            style={{ marginTop: '30px' }}
          >
            스왑 신청 / 상태 조회
          </Button>
        </form>
      )}
    />
  );

  const show = transit ? (
    <Box p={3}>
      <Card style={{
        backgroundColor: '#8080ff', padding: '10px', marginBottom: '40px'
      }}>
        <p>
          현재 보유하고 계신 메인넷 기반의 AMO Coin을 다음 주소로 입금하세요.
        </p>
        
        <p><span color="red">{rName}</span>님의 Klaytn 토큰 스왑용 AMO 지갑입니다.</p>
        <p><span color="red">{rName}</span>님 연락처 : {rPhone}</p>
        <p style={{ overflow: 'auto' }}>
          {transit} 
        </p>
        <p>
          <img src={`https://chart.googleapis.com/chart?chs=225x225&chld=L|2&cht=qr&chl=${transit}`}></img>
        </p>
      </Card>
      <Account address={amo} />
      <DownIcon/>
      <Typography variant="body2">코인 소유주가 직접 송금합니다.</Typography>
      <SwapInput address={transit} />
      <DownIcon/>
      <Typography variant="body2">AMO Labs에서 송금합니다.</Typography>
      <Typography variant="body2" style={{
        color: 'blue', fontWeight: 'bold',
      }}>송금 대기: {mote2amo(kip7ToTransfer).toLocaleString()} AMO(Klaytn)</Typography>
      <SwapOutput address={kip7} />
    </Box>
  ) : null;

  return (
    <div style={{ maxWidth: '800px' }}>
      {form}
      {show}
    </div>
  );
};

export default RequestSwap;
